import Vue from "vue";
import Router from "vue-router";
// import { checkLineUser } from '@/config/line-service'

import store from "@/store";

Vue.use(Router);

const rolePermission = {
  "admin": ["Admin"],
  "admin&admin_brand": ["Admin", "Admin Brand"],
}
const selectRolePermission = "admin&admin_brand";

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // checkLineUser: checkLineUser,
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "",
      redirect: "login",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      meta: { roleUser: rolePermission[selectRolePermission] },
      children: [
        {
          name: "login",
          path: "login",
          component: () => import("@/views/Login"),
          meta: { roleUser: [""] },
          beforeEnter: (to, from, next) => {
            window.localStorage.clear();
            next();
          },
        },
      ],
    },

    {
      path: "",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
      
        {
          name: "rewardreport",
          path: "reward/report/:name/:id",
          component: () => import("@/views/AdminManager/RewardReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Reward" },
        },
        {
          name: "reward-report-code",
          path: "reward/report/code/:name/:id",
          component: () => import("@/views/AdminManager/RewardCodeReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Reward" },
        },
        {
          path: "shipment",
          component: () => import("@/views/AdminManager/Shipment"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Shipment" },
        },
        {
          path: "employee",
          component: () => import("@/views/AdminManager/Employee"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Employees" },
        },
        {
          name: "blog",
          path: "blog",
          component: () => import("@/views/AdminManager/Blog.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Blog" },
        },
        {
          path: "dashboard",
          component: () => import("@/views/AdminManager/Dashboard.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Dashboard" },
        },
        {
          path: "reportKahoot",
          component: () => import("@/views/AdminManager/ReportKahoot.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-ReportKahoot" },
        },
        {
          path: "kahoot-detail/:id/:name",
          name: "kahoot-detail",
          component: () => import("@/views/AdminManager/KahootDetail.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-ReportKahoot" },
        },
      ],
    },
    {
      path: "/mission",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "campaign-list",
          path: "campaign",
          component: () => import("@/views/AdminManager/Campaign"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "campaign-dashboard",
          path: "campaign-dashboard",
          component: () => import("@/views/AdminManager/CampaignDashboard"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "mission-list",
          path: "mission",
          component: () => import("@/views/AdminManager/Mission"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          path: "mission/manage",
          name: "createMission",
          component: () => import("@/components/missions/CreateMission"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "missiondetail",
          path: "mission/:id",
          component: () => import("@/views/AdminManager/MissionDetail"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "missionLuckyDraw",
          path: "mission/lucky/:id",
          component: () => import("@/views/AdminManager/MissionLuckyDraw"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "missionLuckySummary",
          path: "mission/summary/:id",
          component: () => import("@/views/AdminManager/SummaryCode"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "missionreport",
          path: "mission/report/:name/:id",
          component: () => import("@/views/AdminManager/MissionReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "mission-report-anonymous",
          path: "mission/report-anonymous/:name/:id",
          component: () =>
            import("@/views/AdminManager/MissionReportAnonymous"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "missionqr",
          path: "mission/qr-activity/:name/:id",
          component: () => import("@/views/AdminManager/MissionCheckInQr"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "missionreportcheckin",
          path: "mission/report-checkin/:name/:id/:isCheckInOnly",
          component: () => import("@/views/AdminManager/MissionCheckInReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "mission-report-checkin",
          path: "mission/report-checkin/:id/:name/:mission_id/:isCheckInOnly",
          component: () =>
            import("@/views/AdminManager/MissionCheckInQrReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "missionreportexam",
          path:
            "mission/report-exam/:name/:id/:expectedNum/:expectedType/:totalPoint",
          component: () => import("@/views/AdminManager/MissionExamReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "missionreportkahoot",
          path: "mission/report-kahoot/:name/:id",
          component: () => import("@/views/AdminManager/KahootReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "mission-dashboard",
          path: "mission/dashboard/:name/:id",
          component: () => import("@/views/AdminManager/MissionDashboard"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },

        {
          path: "mission-point",
          component: () => import("@/views/AdminManager/MissionPoint"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },

        {
          path: "activity-approve",
          component: () => import("@/views/AdminManager/MissionApprove"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          path: "mission-suggest",
          component: () => import("@/views/AdminManager/MissionSuggest"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },

        {
          path: "category",
          component: () => import("@/views/AdminManager/Category.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "mission-platform",
          path: "mission-platform/:id",
          component: () => import("@/views/AdminManager/MissionPlatform"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "mission-checkin-dashboard",
          path: "mission-checkin-dashboard/:id",
          component: () =>
            import("@/views/AdminManager/MissionCheckInDashboard"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "missionreport-survey-summary",
          path: "mission/reports-survey-summary/:id",
          component: () => import("@/views/AdminManager/MissionReportComponents/SurveySummary"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        {
          name: "missionreport-multi-upload",
          path: "mission/report-multi-upload/:id",
          component: () => import("@/views/AdminManager/MissionReportComponents/MultiUpload"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Campaign" },
        },
        // {
        //   path: "campaign",
        //   component: () => import("@/views/Construction.vue"),
        //   meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Activity" },
        // },
      ],
    },

    {
      path:"/reward",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "reward",
          component: () => import("@/views/AdminManager/Reward"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Reward" },
        },
        {
          path: "reward-category",
          component: () => import("@/views/AdminManager/RewardCategory"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Reward" },
        },
      ],
    },
    {
      path: "/coin",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "history-coin",
          component: () => import("@/views/AdminManager/HistoryCoin.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-HistoryCoin" },
        },
      ],
    },
    {
      path: "/people",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "user",
          component: () => import("@/views/AdminManager/UserAdmin"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Admins" },
        },
        {
          path: "customer",
          component: () => import("@/views/AdminManager/CustomerOnline.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Admins" },
        },
        {
          path: "account",
          component: () => import("@/views/AdminManager/Account.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Admins" },
        },
      ],
    },
    {
      path: "/setting",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "general-setting",
          component: () => import("@/views/AdminManager/Information.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Setting" },
        },
        {
          path: "custom-page",
          component: () => import("@/views/AdminManager/CustomPage"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Setting" },
        },

        {
          path: "register-list",
          name: "register",
          component: () => import("@/views/AdminManager/Register.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Setting" },
        },
        {
          path: "register/manage",
          name: "register-create",
          component: () => import("@/components/campaign/SurveyCampaign.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Setting" },
        },
        {
          path: "register/:id/:name",
          name: "register-user",
          component: () => import("@/views/AdminManager/RegisterUser.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Setting" },
        },
        {
          path: "platform",
          component: () => import("@/views/AdminManager/LinkPlatform.vue"),
          meta: {
            roleUser: rolePermission[selectRolePermission],
            permission: "Access-Setting",
          },
        },
        {
          path: "brand",
          name: "brand",
          component: () => import("@/views/AdminManager/Brand.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Setting" },
        },
        {
          path: "banner",
          component: () => import("@/views/AdminManager/PublicRelations.vue"),
          meta: {
            roleUser: rolePermission[selectRolePermission],
            permission: "Access-Setting",
          },
        },
        {
          path: "customer-level",
          component: () => import("@/views/AdminManager/CustomerLevel.vue"),
          meta: {
            roleUser: rolePermission[selectRolePermission],
            permission: "Access-Setting",
          },
        },
        {
          path: "link-platform",
          component: () => import("@/views/AdminManager/LinkPlatform.vue"),
          meta: {
            roleUser: rolePermission[selectRolePermission],
            permission: "Access-Setting",
          },
        },
      ],
    },
    {
      path: "/report",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "nps-report",
          component: () => import("@/views/AdminManager/NpsReport.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Report" },
        },
        {
          path: "otp-usage",
          component: () => import("@/views/AdminManager/OTPUsage.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Report" },
        },
        {
          path: "transaction-usage",
          component: () => import("@/views/AdminManager/TransactionUsage.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Report" },
        },
      ],
    },
    {
      path: "/microsite",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "templates",
          component: () => import("@/views/AdminManager/Microsite/MicrositeTemplate"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "my-campaign",
          component: () => import("@/views/AdminManager/Microsite/MyCampaign"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "edit/:id",
          component: () => import("@/views/AdminManager/Microsite/EditMicrosite"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "edit/:id/login",
          component: () => import("@/components/microsite/template/LoginPage"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "edit/:id/register",
          component: () => import("@/components/microsite/template/RegisterPage"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "edit/:id/thankyou",
          component: () => import("@/components/microsite/template/ThankYouPage"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "edit/:id/menu",
          component: () => import("@/components/microsite/template/MenuPage"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "edit/:id/history",
          component: () => import("@/components/microsite/template/HistoryPage"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "edit/:id/topSpender",
          component: () => import("@/components/microsite/template/TopSpenderPage"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "edit/:id/pause",
          component: () => import("@/components/microsite/template/PausePage"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "edit/:id/end",
          component: () => import("@/components/microsite/template/EndPage"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "admin-report/:id",
          component: () => import("@/views/AdminManager/Microsite/AdminReport"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        },
        {
          path: "admin-dashboard/:id",
          component: () => import("@/views/AdminManager/Microsite/AdminDashboard"),
          meta: { roleUser: ["Admin"], permission: "Access-Microsite" },
        }
      ],
    },
    // {
    //   path: "/Access-Microsite",
    //   component: () => import("@/layouts/microsite-layout/Layout"),
    //   children: [
    //     {
    //       path: ":id/login",
    //       component: () => import("@/components/microsite/template/LoginPage"),
    //       meta: { roleUser: ["Admin"], permission: "Access-Report" },
    //     },
    //   ],
    // },

    {
      path: "/b2b",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "company",
          component: () => import("@/views/AdminManager/B2B/Company"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "product",
          component: () => import("@/views/AdminManager/B2B/Product"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "customer-service",
          component: () => import("@/views/AdminManager/B2B/CustomerService"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "close-sale",
          component: () => import("@/views/AdminManager/B2B/CloseSale"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "activity",
          component: () => import("@/views/AdminManager/B2B/Activity"),
          meta: { roleUser: ["Admin"] },
        },
      ],
    },

    {
      path: "*",
      name: "error",
      redirect: "login",
      meta: {
        roleUser: [],
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  let role = store.state.defaultSystem.user;
  let roleName = "";
  if (role == null) {
    roleName = "unknown";
  } else {
    roleName = role.role;
  }

  if (!to.meta.roleUser[0]) {
    return next();
  }

  let checkRoleRoutes = to.meta.roleUser
    ? to.meta.roleUser.includes(roleName)
    : next({ name: "error" });

  if (checkRoleRoutes) {
    if (!checkPermission(to)) {
      return next({ name: "error" });
    }
    return next();
  } else {
    return next({ name: "error" });
  }
});

function checkPermission(to) {
  let role = JSON.parse(store.state.defaultSystem.user.permissions);
  let checkRoleRoutes = to.meta.permission;
  let checkPermission = true;
  if (checkRoleRoutes) {
    checkPermission = role.some((item) => item == checkRoleRoutes);
  }
  return checkPermission;
}

export default router;
